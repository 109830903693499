/**
 *------------------------------------------------------------------------------
 * @package       Plazart Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 TemPlaza.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       TemPlaza
 * @Link:         http://templaza.com
 *------------------------------------------------------------------------------
 */
// VARIABLES & MIXINS
// ------------------
@import "../../import/default/vars.less";
// Include Variables and Mixins
// EXTEND STYLE IN BASE
// --------------------
@import "../../../base/megamenu.less";

//
// BASIC STYLE FOR MEGAMENU
// -------------------------
.plazart-mainnav {
  border: none;
  padding: 0;
  margin: 0;
  .navbar-collapse {
    padding: 0;
  }
  .btn-menu {
    display: none;
  }
}

.plazart-megamenu {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  ul {
    li {
      font-size: 16px;

      &.current {
        a {
          color: @color_1;
        }
      }
      a {
        color: @white;
      }
      &.open > a, &.open > a:hover, &.open > a:focus {
        color: @color_1;

      }
      .dropdown-menu {
        li {
          &.active > a,
          &.open > a, &.open > a:hover, &.open > a:focus {
            background: @color_4;
            color: @black;
          }
          &.open {
            > a.mega-group-title {
              background: transparent;
              color: @white;
              &:hover, &:focus {
                background: transparent;
                color: @white;
              }
            }
          }
          &.active > a.mega-group-title {
            background: transparent;
            color: @color_1;
          }
        }
      }
    }
    &.level0 {
      width: 100%;
      text-align: center;
      > li {
        font-weight: 600;
        display: inline-block !important;
        float: none;
        &.active {
          > a {
            color: @color_1;
          }
        }
        > a {
          letter-spacing: 3px;
          text-transform: uppercase;
          padding: 40px 20px;
          line-height: 1.8em;
        }
      }
    }
  }

  // THE MEGAMENU
  //--------------------------------------------

  // Global Menu Inner padding
  // -------------------------

  .tz-mega-menu {
    .mega-col-nav {
      padding: 0 15px;
      margin: 20px 0;
      border-right: 1px solid @color_2c;
      &:last-child,
      .mega-col-nav {
        border: none;
      }
      .mega-col-nav {
        margin: 0;
      }

    }
  }
  .mega-dropdown-menu {
    border-radius: 0;
  }
  .mega-col-nav {
    padding: 0;
  }
  .mega-inner {
    padding: 0;
  }

  // Inner Padding for 1 column
  .col-lg-12 .mega-inner,
  .col-md-12 .mega-inner,
  .col-sm-12 .mega-inner,
  .col-xs-12 .mega-inner {
  }

  // Menu Grids
  // ----------
  .row {
    margin-left: auto;
    margin-right: auto;
  }

  .row + .row {
    padding-top: @plazart-global-padding / 2;
    border-top: 1px solid @hr-border;
  }

  // The Dropdown
  // ------------
  .mega-dropdown-menu {
  }

  // Dropdown Sub Menus
  // ------------------
  .dropdown-submenu {
    > .dropdown-menu {
      margin: 0;
      border-radius: 0;
    }
  }

  // The Group
  // ---------
  .mega-group {
  }

  // Group Title
  // We use BS3 "dropdown-header"
  //.mega-nav .mega-group > .mega-group-title,
  //.dropdown-menu .mega-nav .mega-group > .mega-group-title,
  //.dropdown-menu .active .mega-nav .mega-group > .mega-group-title
  .dropdown-header,
  .mega-nav .mega-group > .dropdown-header,
  .dropdown-menu .mega-nav .mega-group > .dropdown-header,
  .dropdown-menu .active .mega-nav .mega-group > .dropdown-header {
    margin: 0;
    padding: 0;
    background: @plazart-module-title-bg;
    color: @plazart-module-title-color;
    font-size: @font-size-large;
    line-height: normal;
    // Link states
    &:hover, &:active, &:focus {
      background: inherit;
      color: inherit;
    }
  }

  // Group Content
  .mega-group-ct {
    .row {
      margin-left: -(@grid-gutter-width / 2);
      margin-right: -(@grid-gutter-width / 2);
    }
  }

  // Nav in Megamenu
  // ---------------
  .mega-col-nav {
  }

  // Inner padding
  .mega-col-nav .mega-inner {
  }

  // Inner padding for nav in 1 column
  .col-lg-12.mega-col-nav .mega-inner,
  .col-md-12.mega-col-nav .mega-inner,
  .col-sm-12.mega-col-nav .mega-inner,
  .col-xs-12.mega-col-nav .mega-inner {
  }

  .mega-group .col-lg-12.mega-col-nav .mega-inner,
  .mega-group .col-md-12.mega-col-nav .mega-inner,
  .mega-group .col-sm-12.mega-col-nav .mega-inner,
  .mega-group .col-xs-12.mega-col-nav .mega-inner {
  }

  // The Nav
  .tz-mega-menu {
    .dropdown-menu .mega-nav > li a {
      padding: 5px 10px;
    }
  }
  .mega-nav,
  .dropdown-menu .mega-nav {
  }

  .mega-nav > li,
  .dropdown-menu .mega-nav > li {
  }

  .mega-nav > li a,
  .dropdown-menu .mega-nav > li a {
    padding: 10px 25px;
    font-size: 14px;
    letter-spacing: 2px;
    &.mega-group-title {
      text-transform: uppercase;
      color: @white;
      margin-bottom: 5px;
    }
  }
  .dropdown-menu .mega-nav > li .separator {
  }

  // Nav in Group
  .mega-group > .mega-nav,
  .dropdown-menu .mega-group > .mega-nav {
  }

  .mega-group > .mega-nav > li,
  .dropdown-menu .mega-group > .mega-nav > li {
  }

  .mega-group .mega-nav > li a,
  .dropdown-menu .mega-group .mega-nav > li a {
  }
  .dropdown-menu .mega-group .mega-nav > li.current a {
    background: #cccccc;
  }
  .dropdown-menu .mega-group .mega-nav > li.active a {
    color: @gray-dark;
  }

  // The caret
  .mega-nav .dropdown-submenu > a::after {
    margin-right: 0;
    content: none;
  }

  // Modules in Megamenu
  // -------------------
  .mega-col-module {
  }

  // Inner padding
  .mega-col-module .mega-inner {
  }

  // The module
  .plazart-module {
  }

  // Module Title
  .plazart-module .module-title {
    .dropdown-header(); // Make the Module Title look like Dropdown Header
    margin-bottom: 10px;
  }

  // Module Content
  .plazart-module .module-ct {
  }

  // List in Module
  // Reset List Style in Module
  .plazart-module ul,
  .plazart-module .nav {
  }

  .plazart-module ul li,
  .plazart-module .nav li {
    list-style: disc;
    display: list-item;
    float: none;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .plazart-module ul li a,
  .plazart-module .nav li a {
    display: inline;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 100%;
    background: none;
    font: inherit;
    white-space: normal;
    // Link states
    &:hover, &:focus, &:active {
      background: none;
      color: inherit;
      font: inherit;
    }
  }

  // Nav in Module
  .dropdown-menu .plazart-module .nav {
  }

  .dropdown-menu .plazart-module .nav li {
  }

  .dropdown-menu .plazart-module .plazart-module li a {
    // Link states
    &:hover, &:focus, &:active {
    }
  }

  // The caption
  // -----------
  .mega-caption {
    color: @gray-light;
    font-size: @plazart-font-size-smaller;
    margin-top: 3px;
    font-weight: normal;
  }

  // The caret
  // ---------
  .nav .caret,
  .dropdown-submenu .caret,
  .mega-menu .caret {
  }

  // Show the caret on level 0 only
  .nav > .dropdown > .dropdown-toggle .caret {
  }

  // The icon
  // --------
  .nav [class^="icon-"],
  .nav [class*=" icon-"],
  .nav .fa {
    color: #565555;
    margin-right: 10px;
  }

  // Reset the margin on Input Group Addon
  .nav .input-group-addon [class^="icon-"],
  .nav .input-group-addon [class*=" icon-"],
  .nav .input-group-addon .fa {
    margin-right: 0;
  }

  // End
}
