// VARIABLES & MIXINS
// ------------------
// Prevent Bootstrap Upgrading errors
@import "../bootstrap/variables.less";
// Bootstrap mixins
@import "../bootstrap/mixins.less";
// T3 Base variables
@import "variables.less";
@import "legacy-navigation";

//
// BASIC STYLE FOR MEGAMENU
// -------------------------


.plazart-megamenu {

  // THE MEGAMENU
  //--------------------------------------------

  // Global Menu Inner padding
  // -------------------------
  .mega-inner {
    .clearfix();
  }

  // Inner Padding for 1 column
  .col-lg-12 .mega-inner,
  .col-md-12 .mega-inner,
  .col-sm-12 .mega-inner,
  .col-xs-12 .mega-inner {
  }

  // Menu Grids
  // ----------
  .row {
  }

  .row + .row {
  }

  // The Dropdown
  // ------------
  .mega > {
    .mega-dropdown-menu {
      min-width: @plazart-mega-dropdown-min-width;
      display: none;
      padding: 0;
      border: none;
    }
  }

  .mega.open > .mega-dropdown-menu,
  .mega.dropdown-submenu.open > .mega-dropdown-menu {
    display: block;
  }

  // Dropdown Sub Menus
  // ------------------
  .dropdown-submenu {
  }

  // The Group
  // ---------
  .mega-group {
    .clearfix();
  }
  .dropdown-menu {
    background: @black;
  }
  // Group Title
  // We use BS3 "dropdown-header"
  //.mega-nav .mega-group > .mega-group-title,
  //.dropdown-menu .mega-nav .mega-group > .mega-group-title,
  //.dropdown-menu .active .mega-nav .mega-group > .mega-group-title
  .dropdown-header,
  .mega-nav .mega-group > .dropdown-header,
  .dropdown-menu .mega-nav .mega-group > .dropdown-header,
  .dropdown-menu .active .mega-nav .mega-group > .dropdown-header {
    margin: 0;
    padding: 0;
    background: @plazart-module-title-bg;
    color: @plazart-module-title-color;
    font-size: @font-size-large;
    line-height: normal;
    // Link states
    &:hover, &:active, &:focus {
      background: inherit;
      color: inherit;
    }
  }

  // Group Content
  .mega-group-ct {
    margin: 0;
    padding: 0;
    .clearfix();
  }

  // Nav in Megamenu
  // ---------------
  .mega-col-nav {
  }

  // Inner padding
  .mega-col-nav .mega-inner {
  }

  // Inner padding for nav in 1 column
  .col-lg-12.mega-col-nav .mega-inner,
  .col-md-12.mega-col-nav .mega-inner,
  .col-sm-12.mega-col-nav .mega-inner,
  .col-xs-12.mega-col-nav .mega-inner {
  }

  .mega-group .col-lg-12.mega-col-nav .mega-inner,
  .mega-group .col-md-12.mega-col-nav .mega-inner,
  .mega-group .col-sm-12.mega-col-nav .mega-inner,
  .mega-group .col-xs-12.mega-col-nav .mega-inner {
  }

  // The Nav
  .mega-nav,
  .dropdown-menu .mega-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mega-nav > li,
  .dropdown-menu .mega-nav > li {
    list-style: none;
    margin-left: 0;
  }

  .mega-nav > li a,
  .dropdown-menu .mega-nav > li a {
    white-space: normal;
    display: block;
    padding: 5px;
    color: #cbcbcb;
    &:hover,
    &:focus {
      text-decoration: none;
      color: @black;
      background-color: @color_4;
    }
  }

  .mega-nav > li .separator {
    display: block;
    padding: 5px;
  }

  // Nav in Group
  .mega-group > .mega-nav,
  .dropdown-menu .mega-group > .mega-nav {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mega-group > .mega-nav > li,
  .dropdown-menu .mega-group > .mega-nav > li {
  }

  .mega-group .mega-nav > li a,
  .dropdown-menu .mega-group .mega-nav > li a {
  }

  // The caret
  .mega-nav .dropdown-submenu > a::after {
    margin-right: 5px;
  }

  // Modules in Megamenu
  // -------------------
  .mega-col-module {
  }

  // Inner padding
  .mega-col-module .mega-inner {
  }

  // The module
  .plazart-module {
    margin-bottom: @plazart-global-margin / 2;
  }

  // Module Title
  .plazart-module .module-title {
    .dropdown-header(); // Make the Module Title look like Dropdown Header
    margin-bottom: 5px;
  }

  // Module Content
  .plazart-module .module-ct {
    margin: 0;
    padding: 0;
  }

  // The caption
  // -----------
  .mega-caption {
    display: block;
    white-space: nowrap;
  }

  // The caret
  // ---------
  .nav .caret,
  .dropdown-submenu .caret,
  .mega-menu .caret {
    display: none;
  }

  // Show the caret on level 0 only
  .nav > .dropdown > .dropdown-toggle .caret {
    display: none;
  }

  // The icon
  // --------
  .nav [class^="icon-"],
  .nav [class*=" icon-"],
  .nav .fa {
    margin-right: 5px;
  }

  // Reset the margin on Input Group Addon
  .nav .input-group-addon [class^="icon-"],
  .nav .input-group-addon [class*=" icon-"],
  .nav .input-group-addon .fa {
    margin-right: 0;
  }

  // Menu alignment
  // --------------
  .mega-align-left > .dropdown-menu {
    left: 0;
  }

  .mega-align-right > .dropdown-menu {
    left: auto;
    right: 0;
  }

  .mega-align-center > .dropdown-menu {
    left: 50%;
    .translate(-50%, 0);
  }

  .dropdown-submenu.mega-align-left > .dropdown-menu {
    left: 100%;
  }

  .dropdown-submenu.mega-align-right > .dropdown-menu {
    left: auto;
    right: 100%;
  }

  .mega-align-justify {
    position: static;
  }

  .mega-align-justify > .dropdown-menu {
    left: 0;
    margin-left: 0;
    top: auto;
  }

  // End
}

//
// MEGAMENU Animation
// --------------------------------------------------------------

@media (min-width: @grid-float-breakpoint) {
  .plazart-megamenu.animate {
    .mega {
      > .mega-dropdown-menu {
        .backface-visibility(hidden);
        opacity: 0;
      }

      &.animating > .mega-dropdown-menu {
        .transition(all 400ms);
        display: block;
      }

      &.open > .mega-dropdown-menu,
      &.animating.open > .mega-dropdown-menu {
        opacity: 1;
      }
    }

    &.zoom {

      .mega {
        > .mega-dropdown-menu {
          .scale(0, 0);
          .transform-origin(20% 20%);
        }
        &.open > .mega-dropdown-menu {
          .scale(1, 1);
        }
      }

      //special case for level 0
      .level0 > .mega-align-center {
        > .mega-dropdown-menu {
          -webkit-transform: scale(0, 0) translate(-50%, 0);
          -ms-transform: scale(0, 0) translate(-50%, 0);
          transform: scale(0, 0) translate(-50%, 0);

          .transform-origin(0% 20%);
        }

        &.open > .mega-dropdown-menu {
          -webkit-transform: scale(1, 1) translate(-50%, 0);
          -ms-transform: scale(1, 1) translate(-50%, 0);
          transform: scale(1, 1) translate(-50%, 0);
        }
      }
    }

    &.elastic {

      .mega {
        & > .mega-dropdown-menu {
          .scale(0, 1);
          .transform-origin(10% 0);
        }
        &.open > .mega-dropdown-menu {
          .scale(1, 1);
        }
      }

      .level0 {

        > .mega > .mega-dropdown-menu {
          .scale(1, 0);
        }

        .open > .mega-dropdown-menu {
          .scale(1, 1);
        }

        > .mega-align-center {
          > .mega-dropdown-menu {
            transform: scale(1, 0) translate(-50%, 0);
            -webkit-transform: scale(1, 0) translate(-50%, 0);
            -ms-transform: scale(1, 0) translate(-50%, 0);
          }

          &.open > .mega-dropdown-menu {
            transform: scale(1, 1) translate(-50%, 0);
            -webkit-transform: scale(1, 1) translate(-50%, 0);
            -ms-transform: scale(1, 1) translate(-50%, 0);
          }
        }
      }
    }

    &.slide {
      .mega {
        /* Level 0 */
        &.animating > .mega-dropdown-menu {
          overflow: hidden;
        }

        > .mega-dropdown-menu {
          > div {
            .transition(all 400ms);
            .backface-visibility(hidden);
            margin-top: -100%;
          }
        }

        &.open > .mega-dropdown-menu {
          > div {
            margin-top: 0%;
          }
        }

        /* Level > 0 */
        .mega > .mega-dropdown-menu {
          min-width: 0;
          > div {
            min-width: 200px;
            margin-top: 0;
            margin-left: -500px;
            width: 100%;
          }
        }

        .mega.open > .mega-dropdown-menu > div {
          margin-left: 0;
        }
      }
    }
  }
}

//
// MEGAMENU RESPONSIVE
// --------------------------------------------------------------
@media (max-width: @grid-float-breakpoint-max) {

  .plazart-megamenu {

    // THE MEGAMENU
    //------------------------------------------------------

    // Global Menu Inner padding
    // -------------------------
    .mega-inner {
      .clearfix();
    }

    // Inner Padding for 1 column
    .col-lg-12 .mega-inner,
    .col-md-12 .mega-inner,
    .col-sm-12 .mega-inner,
    .col-xs-12 .mega-inner {
    }

    // Menu Grids
    // ----------
    .row,
    .mega-dropdown-menu,
    .row [class*="col-lg-"],
    .row [class*="col-md-"],
    .row [class*="col-sm-"],
    .row [class*="col-xs-"] {
      width: 100% !important;
      min-width: 100% !important;
      left: 0 !important;
      margin-left: 0 !important;

      -webkit-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
    }

    // Hidden when collapse
    .hidden-collapse,
    .always-show .caret,
    .always-show .dropdown-submenu > a:after
    .sub-hidden-collapse > .nav-child,
    .sub-hidden-collapse .caret,
    .sub-hidden-collapse > a:after {
      display: none !important;
    }
  }

  .always-show {
    .mega > .mega-dropdown-menu {
      display: block;
    }
    .navbar-nav .dropdown-menu {
      background-color: transparent;
      border: 0 none;
      box-shadow: none;
      float: none;
      margin-top: 0;
      position: static;
      width: auto;
    }
  }

  // Hide the captions too
  .mega-caption {
    display: none !important;
  }

  // MEGAMENU RTL
  //------------------------------------------------------
  //
  html[dir="rtl"] {
    .plazart-megamenu {
      // Menu Grids
      // ----------
      .row,
      .mega-dropdown-menu,
      .row [class*="col-lg-"],
      .row [class*="col-md-"],
      .row [class*="col-sm-"],
      .row [class*="col-xs-"] {
        left: auto;
        right: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  // End
}
